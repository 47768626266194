











import { Component, Vue } from "vue-property-decorator";

@Component({
  data: () => ({
    namespace: "not-found-view"
  })
})
export default class NotFoundView extends Vue {
}
